import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

/** LOGIN */
const Login = ()=> import('@/pages/Login')
const NotFound = ()=> import('@/pages/Page404')

/** PROFILE */
const Profile = ()=> import('@/views/profile/index.vue')

/** ACCESS */
const Permission = () => import('@/views/access/permission.vue')
const Roles = () => import('@/views/access/roles.vue')
const Users = () => import('@/views/access/users.vue')
const AssignedPermission = () => import('@/views/access/assignedpermission.vue')

/** MANTENIMIENTO */
const Machine = () => import('@/views/mantenance/Machine.vue')
const Service = () => import('@/views/mantenance/Service.vue')
const Supplier = () => import('@/views/mantenance/Supplier.vue')
const Client = () => import('@/views/mantenance/Client.vue')
/** CITAS */
const medicalAppointments= () => import('@/views/medicalAppointment/index.vue')
/** PROCESOS */
const Purchase =() => import('@/views/proccess/Purchase.vue')

//** sorteos */
const Sorteos = ()=> import('@/views/sorteos/index.vue')

/** CAJA */
const Bead = ()=> import('@/views/bead/index.vue')


Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/login',
      name: '',
      component: {
        render (c) { return c('router-view') } //componente 
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    },
    {
      path: '/perfil',
      redirect: '/perfil',
      name: 'Mi perfil',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/',
          name: 'Mis datos',
          component: Profile
        },      
      ]
    },
    {
      path: '/home',
      redirect: '/home',
      name: 'Escritorio',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/',
          name: 'Inicio',
          component: Dashboard
        },      
      ]
    },
    {
      path: '/accesos',
      redirect: '/accesos/permisos',
      name: 'Accesos ',
      component: TheContainer,
      children: [
        {
          path: '/accesos/permisos',
          name: 'Permisos de usuario',
          component: Permission
        },
        {
          path: '/accesos/tipos',
          name: 'Roles de usuario',
          component: Roles
        },
        {
          path: '/accesos/usuarios',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/accesos/permisos_asignados',
          name: 'Permisos Asignados',
          component: AssignedPermission
        },
      ]
    },
    {
      path: '/mantenimientos',
      redirect: '/mantenimientos/maquinas',
      name: 'Mantenimientos ',
      component: TheContainer,
      children: [
        {
          path: '/mantenimientos/maquinas',
          name: 'Máquinas',
          component: Machine
        },
        {
          path: '/mantenimientos/insumos',
          name: 'Insumo',
          component: Supplier
        },    
        {
          path: '/mantenimientos/procedimientos',
          name: 'Procedimientos',
          component: Service
        },      
        {
          path: '/mantenimientos/clientes',
          name: 'Clientes',
          component: Client
        }, 
      ]
    },
    {
      path: '/citas',
      redirect: '/citas',
      name: 'Citas',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/',
          name: 'Citas',
          component: medicalAppointments
        },      
      ]
    },
    {
      path: '/bead',
      redirect: '/bead',
      name: 'Caja',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/',
          name: 'Caja',
          component: Bead
        },      
      ]
    },
    {
      path: '/procesos',
      redirect: '/procesos/compras',
      name: 'Procesos',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/procesos/compras',
          name: 'Compras',
          component: Purchase
        },      
      ]
    },
    /** sorteos */
    {
      path: '/sorteos',
      redirect: '/sorteos',
      name: 'Soteos',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/',
          name: 'Sorteo',
          component: Sorteos
        },      
      ]
    },
    { path: '*', component: NotFound }
  ]
})
